import React from "react"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import CallUs from "../components/call-us";
import ContactForm from "../components/contact-form";
import {graphql} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-gtag";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";


const ContactPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const contact = data.allStrapiContacto.edges[0].node;
    const offices = data.allStrapiOffice.edges;
    const whereWeAre = data.allStrapiDondeEstamos.edges[0].node;
    const principals = offices.filter((office) => office.node.type === 'sede_principal');
    const commercials = offices.filter((office) => office.node.type === 'oficina_comercial');
    let schema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "email": "hello@tecalis.com",
        "name": "Tecalis",
        "telephone": "(34) 91 10 10 700",
        "address": []
    };

    principals.forEach((office) => {
        schema.address.push({
            "@type": "PostalAddress",
            "addressLocality": office.node.city + ', ' + office.node.name + ', ' + office.node.country,
            "postalCode": office.node.postal_code,
            "streetAddress": office.node.address
        })
    });

    return (
        <Layout pageContext={pageContext} cookies={data.cookies}>
            <SEO lang={lang} title={contact.seo.title} description={contact.seo.meta_description}/>

            {/** HABLEMOS
             ================================================== */}
            <section data-speed=".8" className="pt-12 pb-10 pt-md-13 pb-md-11 bg-negro"
                     style={{
                         background: 'linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0), rgba(255, 255, 255, 0)), url(' + require('../img/bg-cta.jpg').default + ')',
                         backgroundPosition: 'center',
                         backgroundSize: 'cover'
                     }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-7">
                            <h1 className="display-1 font-weight-black text-white spacing">
                                {contact.title}
                            </h1>

                            <h2 className="text-white font-weight-bold spacing">
                                <ReactMarkdown children={contact.description} rehypePlugins={[rehypeRaw]} components={{p: ({node, ...props}) => <span {...props} />}}/> <span
                                className="blink">_</span>
                            </h2>
                        </div>

                        <div className="col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <ContactForm data={contact}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CallUs data={contact}/>

            <section className="py-7 py-md-9 mb-8 bg-white">
                <div className="container">
                    <h2 align="left" id="sedes" className="display-4 font-weight-bold spacing">
                        {whereWeAre.principals_title}
                        <br/><br/>
                    </h2>

                    <div className="row">
                        {principals.map((office, i) => {
                            return <div className="col-12 col-xl-4 d-flex" data-aos="fade-down" data-aos-duration={(i + 1) * 100} key={i}>
                                <div className="card bg-cover shadow-light-lg lift locate-card"
                                     style={{backgroundImage: "url('" + office.node.media.localFile.publicURL + "')"}}>
                                    <div className="card-body mt-auto">
                                        <br/>
                                        <h2 className="text-blue font-weight-bold spacing">
                                            {office.node.name}
                                        </h2>

                                        <h4 className="mt-3 mb-0 text-blue spacing font-weight-bold">
                                            {office.node.address}
                                        </h4>

                                        <h6 className="mb-0 text-blue spacing">
                                            {office.node.city}
                                        </h6>
                                    </div>

                                    <OutboundLink href={office.node.map_link} className="card-meta" target="_blank" rel="noopener noreferrer">
                                        <hr className="card-meta-divider border-black-20"/>

                                        <h5 className="text-uppercase text-muted mr-2 mb-0">
                                            <div className="font-weight-bold color-blue">
                                                <i className="fe fe-map-pin"/>
                                            </div>
                                        </h5>
                                    </OutboundLink>
                                </div>
                            </div>
                        })}
                    </div>

                    <br/><br/>

                    <h2 align="left" id="sedes" className="font-weight-bold spacing mt-2">
                        {whereWeAre.commercials_title}
                        <br/><br/>
                    </h2>


                    <div className="row">
                        {commercials.map((office, i) => {
                            return <div className="col-12 col-xl-3 d-flex" data-aos="fade-down" data-aos-duration={((i + 1) * 100) + 100} key={i}>
                                <div className="card bg-cover shadow-light-lg lift locate-card" style={{backgroundImage: "url('" + office.node.media.localFile.publicURL + "')"}}>
                                    <div className="card-body mt-auto"/>

                                    <div className="card-meta">
                                        <hr className="card-meta-divider border-black-20"/>

                                        <h3 align="center" className="text-blue font-weight-bold spacing">
                                            {office.node.name}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ContactPage

export const contactPageQuery = graphql
    `query($lang: String) {
        allStrapiContacto (filter: { locale: { eq: $lang } }) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                    }
                    title
                    description
                    call_title
                    call_number
                    write_title
                    write_email
                    form_name
                    form_name
                    form_email
                    form_phone
                    form_company
                    form_message
                    form_checkbox
                    form_button
                }
            }
        }
        allStrapiOffice {
            edges {
                node {
                    name
                    address
                    postal_code
                    city
                    country
                    description
                    type
                    map_link
                    media {
                        localFile {
                            publicURL
                        }
                    }
                }
            }
        }
        allStrapiDondeEstamos  (filter: { locale: { eq: $lang } }) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                    }
                    title
                    description
                    principals_title
                    commercials_title
                }
            }
        }
        cookies: markdownRemark(
            frontmatter: {
                lang: {
                    eq: $lang
                }
                name: {
                    eq: "cookies"
                }
            }) {
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText
            }
        }
}`;
