import React from "react"
import {OutboundLink} from "gatsby-plugin-google-gtag";

const CallUs = ({data}) => {
    return (
        <section className="py-7 py-md-9 border-bottom border-gray-300 bg-grey" id="info">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">

                        {/** Button */}
                        <a href="#info" className="btn btn-white btn-rounded-circle shadow mt-n11 mt-md-n13 smooth-scroll">
                            <i className="fe fe-arrow-down"/>
                        </a>

                    </div>
                </div>
                {/** / .row */}
                <div className="row">

                    <div className="col-12 col-md-6 text-center border-right border-gray-300">

                        {/** Heading */}
                        <h6 className="text-uppercase text-gray-700 mb-1">
                            {data.call_title}
                        </h6>

                        {/** Link */}
                        <div className="mb-5 mb-md-0">
                            <div className="h2 color-tecalis spacing font-weight-bold contacto-btn">
                                T: {data.call_number}
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-md-6 text-center">

                        {/** Heading */}
                        <h6 className="text-uppercase text-gray-700 mb-1">
                            {data.write_title}
                        </h6>

                        {/** Link */}
                        <OutboundLink href={"mailto:" + data.write_email} className="h2 color-tecalis spacing font-weight-bold contacto-btn">
                            {data.write_email}
                        </OutboundLink>

                    </div>
                </div>
                {/** / .row */}
            </div>
        </section>
    )
}

export default CallUs


