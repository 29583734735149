import React from "react"
import {Link} from "@reach/router";
import {gtagCustomEvent} from "../utils/global-functions";
import ReactMarkdown from "react-markdown";
import {customElements} from "../utils/global-functions";
import contacto from "../pages/contacto";
import rehypeRaw from "rehype-raw";

const ContactForm = ({data}) => {

    const handleSubmit = async () => {
        gtagCustomEvent("contact-us.sent", {
            from: window.location.href,
            time: new Date().toString()
        })
    }

    return (
        <form method="post" action={process.env.SPIRIT_URL + 'contact-form'} id={"contact-form"} onSubmit={handleSubmit}>
            <div className="form-row mt-1">
                <div className="col-12 col-md-6">
                    <div className="form-group mb-5">
                        {/** Input */}
                        <input type="text" className="form-control spacing" id="contactName"
                               name="contactName"
                               required
                               placeholder={data.form_name}/>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="form-group mb-5">
                        <input type="email" className="form-control spacing" id="contactEmail"
                               name="contactEmail"
                               required
                               placeholder={data.form_email}/>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="form-group mb-5">
                        <input type="text" className="form-control spacing" id="contactPhone"
                               name="contactPhone"
                               required
                               placeholder={data.form_phone}/>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="form-group mb-5">
                        <input type="text" className="form-control spacing" id="contactOrganization"
                               name="contactOrganization"
                               required
                               placeholder={data.form_company}/>
                    </div>
                </div>
            </div>
            {/** / .row */}
            <div className="row">
                <div className="col-12">
                    <div className="form-group mb-5 mb-md-5">
                        <textarea className="form-control spacing" id="contactMessage" rows="3"
                                  name="contactMessage"
                                  required
                                  placeholder={data.form_message}/>

                        <br/>

                        <div className="checkbox icheck-primary spacing">
                            <input type="checkbox" id="primary" name="contactConditions" required/>
                            <label htmlFor="primary">
                                <ReactMarkdown children={data.form_checkbox} rehypePlugins={[rehypeRaw]} components={customElements('', 'font-weight-bold')} />
                                {/*{data.frontmatter.privacyStart}&nbsp;
                                <Link to={data.frontmatter.privacyLink} className="font-weight-bold">{data.frontmatter.privacyText}</Link>
                                &nbsp;y&nbsp;
                                <Link to={data.frontmatter.conditionsLink} className="font-weight-bold">{data.frontmatter.conditionsText}</Link>.*/}
                            </label>
                        </div>

                        <div align="center" className="pt-5">
                            <button className="btn btn-primary spacing g-recaptcha"
                                    data-sitekey={process.env.RECAPTCHA_KEY}
                                    data-callback='checkCaptchaForm'
                                    data-action='submit'>
                                {data.form_send} <i className="fe fe-arrow-right d-none d-md-inline ml-3"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default ContactForm
